import React from 'react';
import {
  Switch,
  Route,
  Router,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import BikeInformation from './views/BikeInformation';
const history = createBrowserHistory();

function App() {
  return (
    <Router history={ history }>
      <Switch>
        <Route path='/' component={ BikeInformation } />
      </Switch>
    </Router>
  );
}

export default App;
