import React from 'react';
import Video from '../components/Video';

export default function Content({ objectData }) {
    const { media, location } = objectData;
    if (!objectData) {
        return null;
    }
    return (
        <article className="single__content">
            <div className="container">
                <div className="row justify-content-center no-gutters">
                    <div className="col-12 col-md-8">
                        <h2>Waarmee kunnen we je helpen?</h2>
                        <hr />
                        {(location === 'bike') && (
                            <>
                                <button className="btn btn-cta">Ik zoek een winkel<i className="fa fa-angle-right"></i></button>
                                <button className="btn btn-cta">Ik wil hulp met mijn fiets<i className="fa fa-angle-right"></i></button>
                                <button className="btn btn-cta">Ik heb een probleem met mijn fiets<i className="fa fa-angle-right"></i></button>
                                {media?.pdf && (
                                    <a className="btn btn-cta btn-cta-primary" target="_blank" href={`https://qr-pdf.digitalsolutions.workers.dev/${media?.pdf}`} rel="noreferrer">Gebruikershandleiding bekijken<i className="fa fa-files-o"></i></a>
                                )}
                                {media?.video && (
                                    <>
                                        <h2>Dit is jouw fiets</h2>
                                        <hr />
                                        <Video id={media?.video} />
                                    </>
                                )}
                            </>
                        )}
                        {(location === 'showroom') && (
                            <>
                                <button className="btn btn-cta btn-cta-primary">Ik wil een proefrit maken<i className="fa fa-angle-right"></i></button>
                                <button className="btn btn-cta btn-cta-primary">Ik wil graag een medewerker spreken<i className="fa fa-angle-right"></i></button>
                                <h2>Specificaties</h2>
                                <hr />
                                <ul class="fa-ul">
                                    <li><i class="fa-li fa fa-check-circle-o"></i>Sportieve prestaties en fietsplezier met 1, 3 of 7 versnellingen</li>
                                    <li><i class="fa-li fa fa-check-circle-o"></i>Lichtgewicht aluminium frame met stevige bagagedrager en geintegreerde voorlamp</li>
                                    <li><i class="fa-li fa fa-check-circle-o"></i>Koplamp en achterlicht werken op naafdynamo, geen gedoe met batterijen</li>
                                    <li><i class="fa-li fa fa-check-circle-o"></i>Ook te voorzien van een markante voordrager voor je tas</li>
                                </ul>
                                <h2>Rij-impressie</h2>
                                <hr />
                                <Video id={media?.video} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </article>
    );
}
