import React from 'react';

export default function Navigation({ location }) {
    return (
        <nav className="navbar navbar-expand-lg fixed-top navbar-light" role="navigation">
            <div className="container">
                <div className="navbar-brand">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 744.8 253.2">
                        <g className="logo-icon">
                            <path fill="#FFFFFF" d="M491.6 126.6c0 69.8 56.8 126.6 126.6 126.6s126.6-56.8 126.6-126.6S688 0 618.2 0 491.6 56.8 491.6 126.6z"></path>
                            <path fill="#004A99" d="M500.9 126.7c0 64.7 52.7 117.3 117.3 117.3 64.7 0 117.3-52.6 117.3-117.3C735.5 62 682.9 9.3 618.2 9.3c-64.6 0-117.3 52.7-117.3 117.4z"></path>
                            <path fill="#FFFFFF" d="M617.7 27.7c7 0 13.9.7 20.5 2.1l.3-1.2c-6.5-1.3-13.3-2.1-20.3-2.1-55.3 0-100.1 44.8-100.1 100.1 0 22.6 7.5 43.4 20.1 60.2l5.1-4.4c-12.6-16.1-20.2-36.3-20.2-58.3 0-52.3 42.4-96.4 94.6-96.4zM688.2 187c-17.3 19.4-42.4 31.6-70.5 31.6-25.5 0-48.5-10-65.5-26.4l-5 4.9c18.1 18.2 43.2 29.5 71 29.5 29.9 0 56.7-13.1 75-33.9-1.3-1.5-3.1-3.6-5-5.7z"></path>
                            <path fill="#FFED00" d="M710.6 112.7c-5.8 2.8-12.9 7.6-20.8 13.9-6.4-21.9-12.8-32.5-22.2-44.6 12.4-2.9 15.9-5.3 15.9-5.3l-.8-4.2c-41.8-4.2-56.8-8.9-75.6-15-2.7-.9-3.8-1.3-5.2-1.8-1.1-.4-1.5.3-.5.9 11.5 6.5 23.3 12 36.1 15.4 0 0-5.8 1.9-7.6 2.5-1.9.6-1.7 1.3-.5 1.7 5.8 1.7 11.1 4.5 11.1 4.5l6.5-1.8 4.8 1c5.7 9.2 8.7 17.7 10.6 26.6-4.5 1.5-15.2 2.2-30.9 2.2-20 0-39.3-4.7-39.3-4.7 4.1 6.1 5.5 15 1.9 22.3-8.4 17-23.2 31.2-61 58.4l-.9 15.7c69.3-65 93.6-81.1 127.6-78.7l-7.6 9c-12.6-1.6-37 5.4-51.7 15.2l1.1 1.8c11.4-6.3 26.2-9.8 42.2-8.9 15.3.9 29 5.5 39.5 12.5 11.5-13.2 17.7-20 27.5-29.4l-16.7 38.7c2.7 3.1 5 6.5 6.5 10 4.4-11.1 15.9-41.8 18.9-51.6.7-2.9-4.2-8.6-8.9-6.3z"></path>
                        </g>
                        <path fill="#004A99" d="M268.1 101.7l-13.4.1c-17.5 0-26.4 8.1-26.4 22.4v14.6c0 14.3 8.9 21.7 26.4 21.7h34.7v-12.1h-28.3c-10.7 0-15.3-1.5-15.3-8.9v-1.8l24.9-.2c16.4 0 23-6.8 23-17.5 0-13.4-7.7-18.3-25.6-18.3zm-1.2 24.4h-21.2V120c0-4.8 3.1-5.7 9.2-5.7h10.7c7.2 0 10.5.4 10.5 5.5 0 4.8-3 6.3-9.2 6.3zm142.8-24.4l-13.4.1c-17.5 0-26.4 8.1-26.4 22.4v14.6c0 14.3 8.9 21.7 26.4 21.7H431v-12.1h-28.3c-10.7 0-15.4-1.5-15.4-8.9v-1.8l24.9-.2c16.4 0 23-6.8 23-17.5.1-13.4-7.6-18.3-25.5-18.3zm-1.2 24.4h-21.2V120c0-4.8 3.1-5.7 9.2-5.7h10.7c7.2 0 10.5.4 10.5 5.5 0 4.8-3 6.3-9.2 6.3zM305.1 82.5h17.3v78h-17.3zM71.7 136.8v-11.4H41.2V137h12.9v6.5c0 4.8-3 5.4-9.3 5.4h-12c-10.7 0-15.1-2-15.1-9.4v-26c0-4.8 2.8-5.5 9-5.5h40.7V96.1h-41C8.9 96.1 0 103.5 0 117.8v21.1c0 14.3 8.9 21.6 26.4 21.6h25.4c12.8 0 19.9-6.2 19.9-16.9v-6.8zm86.9-22.2h37.6s-24 20.7-27.3 23.6c-3.4 2.9-10.2 8.9-10.2 11.5v10.9h60.2v-11.7H180s34.3-29.5 35.7-30.7c1.4-1.2 3.2-2.9 3.2-4.8v-10.7h-60.2v11.9zm-36.7-12H87.2v12h28.3c10.7 0 15.3 2 15.3 9.3v1.4h-22.3c-17.9 0-25.6 5.1-25.6 18.5 0 10.7 7.1 16.7 19.9 16.7h45.5v-36.3c.1-14.2-8.8-21.6-26.4-21.6zm9.1 46.3h-21.2c-6.2 0-9.2-.7-9.2-5.5 0-5.1 3.3-6.5 10.5-6.5h20v12zm207.8-66.4h17.3v78h-17.3z"></path>
                    </svg>
                </div>
                {(location === 'showroom') && (
                    <div className="navbar-share">
                        <i class="fa fa-share-alt"></i>
                    </div>
                )}
            </div>
        </nav>
    )
};