import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import Content from '../components/Content';

const decodeUrl = () => {
    const query = new URLSearchParams(window.location.search).get("d");
    if (!query) {
        return null;
    }
    const dataObj = JSON.parse(atob(query));
    console.log(dataObj);
    return dataObj;
}

export default function BikeInformation() {
    const [data, setData] = useState({});
    useEffect(() => {
        setData(decodeUrl());
    }, []);

    if (!data) {
        return null;
    }

    return (
        <>
            <Navigation location={data.location} />
            <Header objectData={data} />
            <Content objectData={data} />
        </>
    );
}
