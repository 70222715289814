import React from 'react';
import ReactPlayer from 'react-player';

export default function Content({ id }) {
    if (!id) {
        return null;
    }
    return (
        <div className="player-wrapper ratio ratio-16x9">
            <ReactPlayer
                url={`https://www.youtube.com/watch?v=${id}`}
                controls
                width="100%"
                height="100%"
                className="react-player"
                config={{
                    youtube: {
                        playerVars: {
                            showinfo: 0,
                            controls: 0,
                            modestbranding: 1,
                            rel: 0,
                        },
                        embedOptions: {
                            host: 'https://www.youtube-nocookie.com',
                        },
                    },
                }}
            />
        </div>
    )
}