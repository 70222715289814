import React from 'react';

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

export default function Header({ objectData }) {
    const { brand, model, media, location } = objectData;
    if (!objectData) {
        return null;
    }
    return (
        <header className="header">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8 header__inner">
                        {(location === 'bike') && (
                            <h1>Hi, dit is jouw {`${capitalize(brand)} ${model}`}</h1>
                        )}
                        {(location === 'showroom') && (
                            <h1>Niets rijdt als een {`${capitalize(brand)} ${model}`}</h1>
                        )}
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row no-gutters header__post">
                    <div className="col-12 header__post-image">
                        {media?.image && (
                            <img src={`https://qr-image.digitalsolutions.workers.dev/${media.image}`} alt="" />
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
}
